<template>
  <div class="checkbox" :class="{ checkbox_checked: isChecked }">
    <div class="checkbox__wrap">
      <svg
        v-show="isChecked"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="10" height="10" rx="5" fill="#D0006F" />
      </svg>
      <input
        type="radio"
        :id="`checkbox_${_uid}`"
        v-model="inputVal"
        :value="inputValue"
        @input="$emit('change')"
      />
    </div>
    <div class="checkbox__text">
      <label
        :for="`checkbox_${_uid}`"
        class="checkbox__label"
        v-html="label"
      ></label>
      <div class="checkbox__error" v-if="isError" v-html="errorText"></div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";
import validate from '@/mixins/validate'
export default {
  name: "RadioButton",
  mixins: [validate],
  props: {
    value: [Boolean, Array],
    label: String,
    rules: Array,
    inputValue: String,
  },
  data: () => ({
    errorText: "",
    isError: false,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", [val]);
      },
    },
    isChecked() {
      return this.inputVal.includes(this.inputValue);
    },
  },
  created() {
    bus.$on("validate", this.validate);
  },
  destroyed() {
    bus.$off("validate", this.validate);
  },
  watch: {
    inputVal() {
      bus.data.result = []
      this.validate(this._uid);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  &__wrap {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 2px solid #f6cce2;
    border-radius: 50%;
    flex-shrink: 0;
    margin-top: 2px;

    & input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1000;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 11px;
  }

  &__label {
    margin-top: 1px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #3c4242;
  }

  &__error {
    margin-top: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #cb4050;
  }
}
</style>

<style lang="scss">
.checkbox__label {
  & > a {
    color: #d0006f;
  }
}
</style>